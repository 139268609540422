<template>
  <div class="scanJfen">
    <div class="box" v-for="item in dataList" :key="item.id">
      <div class="table">
        <div class="title">
          <div style="font-size: 20px">{{ item.mahjongNumber }}号桌</div>
          <div
            style="text-align: center; margin-top: 5px"
            :class="item.status === '0' ? 'c_grey' : 'c_yellow'"
          >
            {{ item.status === "0" ? "空闲" : "进行中" }}
          </div>
        </div>
        <div class="people up" v-if="item.upUserInfo">
          <img
            :src="item.upUserInfo.headUrl || './images/head.png'"
            style="width: 30px; height: 30px; border-radius: 30px"
          />
          <span>{{ item.upUserInfo.nickName }}</span>
        </div>
        <div class="people down" v-if="item.downUserInfo">
          <img
            :src="item.downUserInfo.headUrl || './images/head.png'"
            style="width: 30px; height: 30px; border-radius: 30px"
          />
          <span>{{ item.downUserInfo.nickName }}</span>
        </div>
        <div class="people left" v-if="item.leftUserInfo">
          <img
            :src="item.leftUserInfo.headUrl || './images/head.png'"
            style="width: 30px; height: 30px; border-radius: 30px"
          />
          <span>{{ item.leftUserInfo.nickName }}</span>
        </div>
        <div class="people right" v-if="item.rightUserInfo">
          <img
            :src="item.rightUserInfo.headUrl || './images/head.png'"
            style="width: 30px; height: 30px; border-radius: 30px"
          />
          <span>{{ item.rightUserInfo.nickName }}</span>
        </div>
      </div>
      <div class="btnBox">
        <el-button type="primary" size="small" @click="emptyTable(item)"
          >清台</el-button
        >
        <el-button
          v-if="item.status === '0'"
          type="success"
          size="small"
          @click="beginTable(item)"
          >开始</el-button
        >
        <el-button
          v-if="item.status === '1'"
          type="warning"
          size="small"
          @click="endTable(item)"
          >结算</el-button
        >
      </div>
    </div>
    <img src="./images/refash.png" alt="" class="refash" @click="initPage()">
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
      timer: null,
    };
  },
  methods: {
    emptyTable(item) {
      //清空座位
      this.$dialog
        .confirm({
          message: "确定清空座位吗？",
        })
        .then(() => {
          this.getAjax("api/mahjong/emptyTable", { id: item.id }, "post").then(
            (res) => {
              this.initPage();
              this.message("操作成功");
            }
          );
        });
    },
    beginTable(item) {
      //开始
      this.$dialog
        .confirm({
          message: "确定开始吗？",
        })
        .then(() => {
          this.getAjax(
            "api/mahjong/setStatusGoing",
            { id: item.id },
            "post"
          ).then((res) => {
            this.initPage();
            this.message("操作成功");
          });
        });
    },
    endTable(item) {
      //结算
      this.$dialog
        .confirm({
          message: "确定结算吗？",
        })
        .then(() => {
          this.getAjax("api/mahjong/checkOut", { id: item.id }, "post").then(
            (res) => {
              this.initPage();
              this.message("操作成功");
            }
          );
        });
    },
    initPage() {
      this.getAjax(
        "api/mahjong/list",
        {
          page: 1,
          pageSize: 100,
        },
        "get"
      ).then((res) => {
        this.dataList = res.data.list.records;
      });
    },
  },
  mounted() {
    this.initPage();
    this.timer = setInterval(() => {
      this.initPage();
    }, 3000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped lang="less">
.scanJfen {
  overflow: hidden;
  .refash{
    position: fixed;
    right: 20px;
    bottom:20px;
    z-index:9;
    width: 40px;
  }
  .box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    border-bottom: 1px solid #ddd;
    padding-bottom: 75px;
    background: #fff;
    position: relative;

    .table {
      width: 100px;
      height: 100px;
      border: 2px solid #38ce07;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      color: #299a05;
      margin-bottom: 40px;

      .people {
        position: absolute;
        top: -40px;
        display: flex;
        align-items: center;

        span {
          font-size: 11px;
          color: #666;
          margin-left: 3px;
        }
      }

      .left {
        top: 30px;
        left: -90px;
      }

      .down {
        top: 0;
        bottom: -160px;
      }

      .right {
        right: -90px;
        top: 30px;
      }
    }
    .btnBox {
      width: 100%;
      position: absolute;
      bottom: 10px;
      left: 0;
      height: 40px;
      display: flex;
      padding: 0 5%;
      box-sizing: border-box;
      button {
        flex: 1;
      }
    }
  }
}
</style>
